import React, { useState, useEffect } from 'react';
import './App.css';

const LandingPage = () => {
  const fullText = 'Dedicated to researching, documenting, and exposing antisemitism.';
  const [text, setText] = useState('');

  useEffect(() => {
    let index = 0;
    setText(fullText.charAt(0));  
    const intervalId = setInterval(() => {
      index++;  
      if (index < fullText.length) {
        setText(prevText => prevText + fullText.charAt(index));
      } else {
        clearInterval(intervalId);
      }
    }, 40);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container">
      <h1 className="header">The <span className="red-text">Hate</span> Archive</h1>
      <p className="text">{text}</p>
    </div>
  );
};

export default LandingPage;
